// extracted by mini-css-extract-plugin
export var collaborators = "about-module--collaborators--BVpTS";
export var container = "about-module--container--GpJlX";
export var credentials = "about-module--credentials--hf6gu";
export var defaultButton = "about-module--default-button--2rJXi";
export var degree = "about-module--degree--Cus4l";
export var expandButton = "about-module--expand-button--JNP1s";
export var github = "about-module--github--xifI1";
export var gradIcon = "about-module--grad-icon--3xiv9";
export var header = "about-module--header--hIUMa";
export var homeImage = "about-module--home-image---8C8E";
export var homeLinks = "about-module--home-links--+WuT5";
export var homeTitle = "about-module--home-title--wDvr6";
export var html = "about-module--html--A1uD5";
export var jobTitle = "about-module--job-title--6mFuF";
export var jobs = "about-module--jobs--OWjG0";
export var journalTitle = "about-module--journal-title--XkWLf";
export var logoBerkeley = "about-module--logo-berkeley--fsyIb";
export var logoRutgers = "about-module--logo-rutgers--M23Ae";
export var logoYale = "about-module--logo-yale--bhj0G";
export var moreInfo = "about-module--more-info--ia3c4";
export var pageTitle = "about-module--page-title--qPSaa";
export var plusSign = "about-module--plus-sign--GSylu";
export var projectBlock = "about-module--project-block--etncn";
export var projectImage = "about-module--project-image--c94Xr";
export var projectInfo = "about-module--project-info--rYqX3";
export var projectTitle = "about-module--project-title--yHMZF";
export var pubDate = "about-module--pub-date--rH0uk";
export var schoolName = "about-module--school-name--PVLm+";
export var schools = "about-module--schools--K6v2S";
export var schoolsLogo = "about-module--schools-logo--eLkIg";
export var schoolsText = "about-module--schools-text--bHgqh";
export var schoolsss = "about-module--schoolsss--lm0KX";
export var timeline = "about-module--timeline--zLHwl";
export var workIcon = "about-module--work-icon--+DO-s";
export var workText = "about-module--work-text--WGwpp";
export var workTimeline = "about-module--work-timeline--8wWJh";
export var workTitle = "about-module--work-title--X08ec";
export var year = "about-module--year--IsWdn";